class OticonQuiz {
	constructor() { 
		this.currentStep = 0
	}
	validateStep(el) { 
		const radioBoxes = el.closest('.oticon-quiz__question__answers').querySelectorAll('input[type="radio"]')
		radioBoxes.forEach(radio => { 
			if (radio.checked) { 
				if (radio.value === '1') {
					this.showNextStep()
				} else { 
					this.showModal()
				}
			}
		})
	}

	showForm() { 
		const steps = document.querySelectorAll('.oticon-quiz__question')
		const quizForm = document.querySelector('.oticon-quiz__form')
		steps.forEach(step => step.classList.remove('active'))
		quizForm.classList.add('active')

	}

	showNextStep() {
		this.currentStep++
		const steps = document.querySelectorAll('.oticon-quiz__question')
		const quizForm = document.querySelector('.oticon-quiz__form')
		steps.forEach(step => {
			console.log(step)
			if (step.dataset.question === this.currentStep.toString()) {
				step.classList.add('active')
			} else { 
				step.classList.remove('active')
			}
		
		})
		if (this.currentStep >= steps.length ) {
			quizForm.classList.add('active')
		}
	 }

	closeModal() { 
		const modal = document.querySelector('.oticon-quiz__form__error')
		return modal.classList.remove('active')
	}

	showModal() {
		const modal = document.querySelector('.oticon-quiz__form__error')
		return modal.classList.add('active')
	}
}
if (document.querySelector('.oticon-quiz')) {
	const oticonQuiz = new OticonQuiz()
	window.addEventListener('keyup', (event) => {
		if (event.key === 'Escape') {
			oticonQuiz.closeModal()
		}
	})
	
}
