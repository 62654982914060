if (document.querySelector('header:not(#quiz-header) .content')) {
	const header = document.querySelector('header:not(#quiz-header) .content')
	const headerImage = document.querySelector('header:not(#quiz-header) .header__background')

	const offsetYScroll = (el, amount) => {
		el.style.transform = `translateY(${window.scrollY / amount}px)`
	}
	window.addEventListener('scroll', e => {
		offsetYScroll(header, 1.2)
		offsetYScroll(headerImage, 1.2)
	})

	// IntersectionObserver
	let scrollElements = document.querySelectorAll('.scrollEvent')
	if ('IntersectionObserver' in window && window.innerWidth > 600) {
		
		
		const options = {
			root: null,
			rootMargin: "0px",
			threshold: 0.2
		}
		
		const callback = (entries, observer) => {
			entries.forEach(entry => {

				if (entry.isIntersecting) {
					console.log(entry.target, entry.intersectionRatio)
					entry.target.classList.add('INTERSECTING')
					observer.unobserve(entry.target);
				}
				// else {
				//	 entry.target.classList.remove('INTERSECTING')
				// }
			})
		}
		
		const observer = new IntersectionObserver(callback, options)
		
		if (scrollElements) {
			scrollElements.forEach(el => {
				observer.observe(el)
			})
		}
	} else {
		scrollElements.forEach(el => el.classList.remove('scrollEvent'))
	}
}